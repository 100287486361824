a {
    text-decoration: none;
}

.projectWrapper {
    display: flex;
    gap: 2rem;
    width: 74vw;
    margin: 0 auto;
    padding-bottom: 4rem;
}

.img {
    width: clamp(30vw, 40vw, 50vw);
    border-radius: 7px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.25);
}

.main {
    width: clamp(25vw, 40vw, 50vw);
    text-align: center;
    color: #2d2e32;
}

.techStack {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}


.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.contact > a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.name {
    font-weight: bold;
}

.icon {
    font-size: 1.5rem;
}

.icon:hover {
    transform: scale(1.05);
    color: #07338c;
}

@media (max-width: 415px) {

    .projectWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
    padding-bottom: 2rem;
    }

    .img {
        width: 80vw;
    }

    .main {
        width: 80vw;
    }

    .name {
        font-size: 1.5rem;
    }

    .icon {
        font-size: 1.9rem;
    }

}