a {
    color: inherit;
}

html {
    overflow-x: hidden;
}

.footerWrapper  {
    background-color: #69788c;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.contact {
    display: flex;
    gap: 1.5rem;
    font-size: 1.5rem;
}

.icon {
    transition: 0.1s;
}

.icon:hover {
    transform: scale(1.08);
    color: #07338c;
}

@media (max-width: 415px) {

    .contact {
        display: none;
    }

    .footerWrapper {
        justify-content: center;
    }

    .h1 {
        font-size: 1.2rem;
        text-align: center;
    }

}