.portfolio {
    display: flex;
    flex-direction: column;
    padding: 2rem 22%;
}

.h3 {
    color: #07338c;
    margin: 0;
}

.h2 {
    color: #2d2e32;
    margin: 0;
}

.p {
    display: flex;
    padding: 2rem 22%;
    color: #2d2e32;
}

@media (max-width: 415px) {

    .portfolio {
        padding: 2rem 0;
    }

    .h3 {
        text-align: center;
    }

    .h2 {
        text-align: center;
    }

    .p {
        justify-content: center;
        padding: 2rem 0;
    }

}