.aboutWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    background-color: #f7f7f7;
}

.img {
    width: clamp(25vw, 30vw, 50vw);
    height: 100%;
    border-radius: 15px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.15);
}

.main {
    width: clamp(25vw, 30vw, 70vw);
    margin: 0;
    padding: 5rem 5rem;
}

.h3 {
    color: #07338c;
    margin: 0;
}

.h2{
    color: #2d2e32;
    margin: 0;
}

.p{
    display: inline-block;
    font-size: 0.9em;
    text-align: justify;
    color: #555;
    margin: 0;
}

@media (max-width: 415px) {
    
    .aboutWrapper {
        padding: 2rem 0 2rem 0;
        flex-direction: column;
    }
    
    .img {
        width: 80vw;
    }

    .main {
        width: 70vw;
        padding: 0;
    }

    .h3 {
        text-align: center;
    }

    .h2 {
        text-align: center;
        padding-bottom: 1.5rem;
    }

    .p {
        text-align: justify;
    }

}