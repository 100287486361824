.contactWrapper {
    background-color: #f7f7f7;
    padding: 2rem 22%;
}

.h4 {
    margin: 0;
    color: #07338c;
}

.h3 {
    margin: 0;
    color: #2d2e32;
    padding-bottom: 2rem;
}

.contacts {
    display: flex;
    align-items: center;
    gap: 5rem;
}

.contact {
    display: flex;
    align-items: center;
}

.icon {
    padding: 7px;
    margin-right: 15px;
    border-radius: 50%;
    color: #07338c;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.iDontKnow > p{
    margin: 0;
    color: #2d2e32;
}

@media (max-width: 415px) {

    .contactWrapper {
        padding: 2rem;
        text-align: center;       
    }

    .contacts {
        flex-direction: column;
        align-items: start;
        gap: 2rem;
    }

    .iDontKnow > p {
        text-align: left;
    }

}