.notclicked {
    display: none;
}

.notNavMain {
    display: none;
}

.NotMobileNav {
    display: none;
}

.clicked {
    display: block;
    background-color: #69788c;
    position: sticky;
    top: 0;
    height: 100vh;
    z-index: 100;
}


.navMain {
    display: flex;
    align-items: top;
    justify-content: space-between;
    padding: 0 2rem 0 2rem;
}

.navButton {
    padding-top: 1rem;
}

.navIcon {
    font-size: 1.5rem;
}

.navTitle {
    display: flex;
    gap: 0.3rem;
    cursor: pointer;
    padding: 0.5rem 0 0.5rem 0;
}

.navMatheus {
    margin: 0;
    font-size: 1.5em;
    color: #07338c;
}

.navRamazza {
    margin: 0;
    font-size: 1.5em;
    font-weight:normal;
}

.mobileUl {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
    margin: 0;
}

.mobileLi {
    font-size: 3rem;
    font-weight: 450;
    list-style: none;
    cursor: pointer;
}

.main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem 0 2rem;
    background-color: #69788c;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 0;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    cursor: pointer;
    padding: 0.5rem 0 0.5rem 0;
}

.matheus {
    margin: 0;
    font-size: 2em;
    color: #07338c;
}

.ramazza {
    margin: 0;
    font-size: 2em;
    font-weight:normal;
}

.ul {
    display: flex;
    gap: 1rem;
}

.li {
    font-size: 1.5rem;
    font-weight: 450;
    list-style: none;
    cursor: pointer;
}

.li:hover {
    color: #07338c;
}

.icon {
    display: none;
    font-size: 1.5rem;
}

@media (max-width: 415px) {

    .nav {
        display: none;
    }

    .matheus {
        font-size: 1.5rem;
    }

    .ramazza {
        font-size: 1.5rem;
    }

    .icon {
        display: block;
    }

}