.heroContainer {
    width:  50vw;
    margin: 0 auto;
    padding: 10rem 0 12rem 0;
    display: flex;
}

.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.h1 {
    width: 100%;
    font-size: 2.2em;
    color: #2d2e32;
    margin: 0;
}

.span {
    display: flex;
    width: 85%;
    color: #2d2e32;
}

.contact {
    display: flex;
    gap: 0.5rem;
    font-size: 1.5em;
}

.icon:hover {
    color: #07338c;
}

.selfie {
    background-image: url('../../assets/selfie.png');
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-position: 50%;
    background-size: 90%;
    border: solid 4px #07338c;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.15);
}

@media (max-width: 415px) {

    .heroContainer {
        flex-direction: column-reverse;
        align-items: center;
        padding: 3rem 0 3rem 0;
        width: 80vw;
    }

    .h1 {
        padding-top: 2rem;
        text-align: center;
    }

    .span {
        width: 100%;
        text-align: center;
    }

    .contact {
        font-size: 2rem;
        justify-content: center;
    }

}