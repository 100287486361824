.techStackWrapper {
    display: flex;
    gap: 0.7rem;
    align-items: center;
    padding-top: 4rem;
}

.techStack {
    display: flex;
    gap: 0.7rem;
    align-items: center;
}

.p {
    font-weight: bold;
}

@media (max-width: 415px) {

    .techStackWrapper {
        padding-top: 0;
        flex-direction: column;
    }

    .techStack {
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;
    }

}